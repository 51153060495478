import React from "react"
import { Grid, Themed } from "theme-ui"
import Header from "../components/header"
import Footer from "../components/footer"

const LayoutFullHeight = ( props ) => {

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
            gridTemplateColumns: [  `1fr`,
                                    `1fr`,
                                    `1fr`,
                                    `30vw 1fr`,
                                    `30vw 1fr`,
                                    `30vw 1fr`
                                  ],
            gridTemplateRows: [ `4rem auto auto auto`,
                                `4rem auto auto auto`,
                                `4rem auto auto auto`,
                                `0 1fr 0`,
                                `0 1fr 0`,
                                `0 1fr 0`
                              ],
            gridTemplateAreas:[ `"header" "aside" "main" "footer"`, 
                                `"header" "aside" "main" "footer"`, 
                                `"header" "aside" "main" "footer"`, 
                                `"aside header" "aside main" "aside footer"`, 
                                `"aside header" "aside main" "aside footer"`,
                                `"aside header" "aside main" "aside footer"`,
                              ],
            height: [`auto`, `auto`, `auto`, `100vh`, `100vh`, `100vh`],
            overflow: [`auto`, `auto`, `auto`, `hidden`, `hidden`, `hidden`],
            width: `100vw`,
            header: {
              gridArea: `header`,
              position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`, `fixed`],
              top: `1.1rem`,
              right: 4,
              zIndex: 1,
            },
            ".section-aside": {
              gridArea: `aside`,
              height: [`auto`, `auto`, `auto`, `100vh`, `100vh`, `100vh`],
              position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`, `fixed`],
              width: [`100vw`, `100vw`, `100vw`, `30vw`, `30vw`, `30vw`],
            },
            main: {
              gridArea: `main`,
              height: [`auto`, `auto`, `auto`, `100%`, `100%`, `100%`],
              position: `relative`,
            },
            footer: {
              gridArea: `footer`,
              position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`, `fixed`],
              bottom: 0,
              right: 4,
            }
          }}
      >
        <Header />
        {props.children}
        <Footer />
      </Grid>
    </Themed.root>
  )

}

export default LayoutFullHeight