import React from "react"
import { graphql } from "gatsby"
import { Box, Themed } from "theme-ui"
import LayoutFullHeight from "../components/layout-full-height"
import Aside from "../components/aside"
import PathCentroamerica from "../components/path-centroamerica"
import MenuSocial from "../components/menu-social"
import { useMediaQuery } from 'react-responsive'
import { lighten } from "@theme-ui/color"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {

  const dataFragments = data.dataJson

  // Min 1600
  const BigScreen = ({ children }) => {
    const isBigScreen = useMediaQuery({ minWidth: 1600 })
    return isBigScreen ? children : null
  }
  // Min 1368 Max 1599. All 1440
  const Desktop1440 = ({ children }) => {
    const isDesktop1440 = useMediaQuery({ minWidth: 1368, maxWidth: 1599 })
    return isDesktop1440 ? children : null
  }
  // Min 1282 Max 1367. All 1366
  const Desktop1366 = ({ children }) => {
    const isDesktop1366 = useMediaQuery({ minWidth: 1282, maxWidth: 1367 })
    return isDesktop1366 ? children : null
  }
  // MinMax 1024 Max 1281. All 1280
  const Desktop1280 = ({ children }) => {
    const isDesktop1280 = useMediaQuery({ minWidth: 1024, maxWidth: 1281 })
    return isDesktop1280 ? children : null
  }
  // Tablet
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 })
    return isTablet ? children : null
  }
  // Mobiles
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  return (
    <LayoutFullHeight>
      <Seo
        description={dataFragments.fragment_2}
      />
      <Aside>
        <Box
          className="text-container"
          sx={{
            mb: 4,
            h1: {
              fontSize: [1, 1, 1, 3, 3, 5],
              ":after": {
                backgroundColor: lighten(`primary`, 0.4),
                content: `""`,
                display: `block`,
                height: `1px`,
                width: 6,
                my: 3,
              },
            },
            a: {
              color: lighten(`primary`, 0.4),
              borderBottom: `none`,
            },
            p: {
              fontSize: [1, 1, 1, 1, 1, 2],
              mb: 2,
            },
            ".disclaimer": {
              display: `block`,
              fontSize: [0, 0, 0, 0, 1, 1],
              lineHeight: `snug`,
            },
          }}
        >
          <Themed.h1 dangerouslySetInnerHTML={{ __html: dataFragments.fragment_1}}  />
          <Themed.p
            dangerouslySetInnerHTML={{ __html: dataFragments.fragment_2}}
          />
          <Themed.p
            dangerouslySetInnerHTML={{ __html: dataFragments.fragment_3}}
          />
          <Themed.p
            dangerouslySetInnerHTML={{ __html: dataFragments.fragment_4}}
          />
          <Themed.p
            className="disclaimer"
            dangerouslySetInnerHTML={{ __html: dataFragments.fragment_5}}
          />
        </Box>
        <MenuSocial page="frontpage" />
      </Aside>
      <Box
        as={`main`}
        className="map-container"
        sx={{
          backgroundColor: `backgroundmaps`,
          overflow: `hidden`,
          "#map": {
            width: `100%`,
          }
        }}
      >
        <BigScreen>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="1 26 300 255"
          >
            <PathCentroamerica />
          </Box>
        </BigScreen>
        <Desktop1440>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="15 30 250 240"
          >
            <PathCentroamerica />
          </Box>
        </Desktop1440>
        <Desktop1366>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="9 45 260 240"
          >
            <PathCentroamerica />
          </Box>
        </Desktop1366>
        <Desktop1280>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="10 31 252 280"
          >
            <PathCentroamerica />
          </Box>
        </Desktop1280>
        <Tablet>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="10 40 250 205"
          >
            <PathCentroamerica />
          </Box>
        </Tablet>
        <Mobile>
          <Box
            as={`svg`}
            id="map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="20 40 230 190"
            sx={{
              height: `100%`,
            }}
          >
            <PathCentroamerica />
          </Box>
        </Mobile>
      </Box>
    </LayoutFullHeight>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    dataJson(slug: {eq: "page-index"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
    }
  }
`
